import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { TanHeader, BannerLeft, BannerButton } from "../utils"
import img from "../images/bcg/takeout-delivery-service-truck.png"

const TakeoutPage = () => (
  <Layout>
    <SEO title="Takeout" keywords={[`The Best Dang Takeout in Sylva, Dillsboro, and greater Jackson County NC`]} />
    <TanHeader img={img}>
      <BannerLeft title="Takeout" subtitle="Take it To-Go!">
        <hr />
        <p>
          Place your takeout order online or call us.
        </p>
      </BannerLeft>
    </TanHeader>

    <section id="takeout">
      <div className="row px-0 py-5 justify-content-center">
        <div className="col-12 col-md-10 col-lg-9 px-0 py-4 mx-0">
          <h2>Oh the Places You'll Go!</h2>
          <p>
            You might like to enjoy your food in a boat with a goat OR in the rain on a train OR in your house with a mouse.
          </p>
          <br></br>
          <BannerButton>
            <a href="https://foragerscanteen.revelup.com/weborder/" rel="noopener noreferrer" target="_blank">ORDER ONLINE NOW</a>
          </BannerButton>
          <br></br>
          <BannerButton>
            <a href="tel:828-631-4114" rel="noopener noreferrer">Call 828-631-4114</a>
          </BannerButton>
        </div>
      </div>
      
    </section>
  </Layout>
)

export default TakeoutPage
